import { Button } from "@mui/material";

const Pagination = ({
  handlePrevPage,
  handleNextPage,
  currentPage,
  totalPages,
}) => {
  return (
    <div className="d-flex justify-content-center align-items-center pt-2">
      <Button
        color="primary"
        variant="outlined"
        onClick={handlePrevPage}
        disabled={currentPage && currentPage === 1 ? true : false}
      >
        Previous
      </Button>
      <span className="me-2 ms-2">
        Page {currentPage} of {totalPages || 1}
      </span>
      <Button
        color="primary"
        variant="outlined"
        onClick={handleNextPage}
        disabled={currentPage && currentPage === (totalPages || 1) ? true : false}
      >
        Next
      </Button>
    </div>
  );
};

export default Pagination;
