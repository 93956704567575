import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Fade from "react-reveal/Fade";
import axios from "axios";
import { baseUrl } from "../../utils/constants";
import Pagination from "../Pagination";

export default function PropertyAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 10;
  useEffect(() => {
    const headers = { lang: "ar" };
    axios
      .get(`${baseUrl}/properties?page=${currentPage}&perPage=${perPage}`, {
        headers,
      })
      .then((response) => {
        setPosts(response.data.data);
        setTotalPages(response.data.pagination.totalPages);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1 dir="rtl" lang="ar">
            {" "}
          A.S. Properties{" "}
          </h1>
          <Fade bottom duration={1000} delay={500}>
            {" "}
            <p dir="rtl" lang="ar">
              حلول متقدمة لمنزلك الجديد
            </p>
          </Fade>
          <ol
            class="breadcrumbAr"
            dir="rtl"
            lang="ar"
            style={{
              position: "absolute",
              right: "15px",
              bottom: "-30px",
              background: "#9f8054",
              width: "85%",
              padding: "40px 15px",
              left: "auto",
              margin: "0",
            }}
          >
            <li class="breadcrumbAr-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                الرئيسية
              </Link>
            </li>
            <li class="breadcrumbAr-item active" aria-current="page">
              العقارات{" "}
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{ padding: "60px 0" }}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp" dir="rtl" lang="ar">
              {" "}
              <b>01</b>
              <Fade bottom duration={1000} delay={500}>
                {" "}
                <h4>
                  عقارات <span>A.S.</span> Properties
                </h4>
              </Fade>
              <small>اقرأ عقاراتنا </small>{" "}
            </div>
            {posts.map((post) => (
              <div class="col-lg-4 wow fadeInUp"  dir="rtl" lang="ar" data-wow-delay="0s">
                <div class="post-box">
                  <figure>
                    {" "}
                    <Link to={`/property/${post._id}`}>
                    <img src={post.images[0]} alt="Image" />{" "}
                    </Link>
                  </figure>
                  <h6 className="d-block w-100">
                    <Link
                      to={`/property/${post._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {post.title}
                    </Link>
                  </h6>

                  <p className="d-block w-100"> {post.shortDescription}</p>
                  <Link to={`/property/${post._id}`}>
                    <button className="readMore">
                      اقرأ المزيد &nbsp;<i class="fas fa-caret-left"></i>
                    </button>
                  </Link>
                </div>
              </div>
            ))}
             <Pagination
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          </div>
        
        </div>
      </section>
    </>
  );
}
