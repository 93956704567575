import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import news1 from "../Images/news1.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/constants";

export default function News1() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let { id } = useParams();
  const [post, setPost] = useState({});
  useEffect(() => {
    axios
      .get(`${baseUrl}/blog/${id}`)
      .then((response) => {
        setPost(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1> A.S. Properties News</h1>
          <p>Advanced Solutions to your new home</p>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                Home
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              A.S. Properties News
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{ padding: "40px 0" }}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center">
              {" "}
              {/* <b>01</b> */}
              <h1>{post.title} </h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={post.image} alt="" />
            </div>
            <div className="col-md-12">
              <p style={{ textAlign: "justify" , whiteSpace: "pre-line"}}>{post.description}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
