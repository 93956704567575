import React, { useState } from "react";
import logo from "../Images/Logo with Name.png";
import phone from "../Images/icon-phone.png";
import { Link } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export default function Navbar({ ar, setAr }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    console.log("buttonClicked");
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <>
      <nav class="navbar" style={{ padding: "0", position: "absolute" }}>
        {isMobileMenuOpen ? (
          <div className="side-navigation">
            <div className="menu">
              <ul>
                <li>
                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gallery"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    Gallery
                  </Link>
                </li>
                <li><Link to='/property' style={{textDecoration:'none'}}>Property</Link>
       
        </li>
                <li>
                  <Link
                    to="/news"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    News
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              <div class="language">
                <a
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    setAr("ar");
                    localStorage.setItem("lang", "ar");
                  }}
                >
                  AR
                </a>{" "}
              </div>
            </div>
          </div>
        ) : null}
        <div class="container">
          <div class="upper-side" style={{ transform: "translateY(0)" }}>
            <div class="logo">
              {" "}
              <Link to="/">
                <img src={logo} alt="Image" />
              </Link>{" "}
            </div>
            <div class="phone-email">
              {" "}
              <img src={phone} alt="Image" />
              <h4>
                <a
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    opacity: "0.5",
                  }}
                  href="tel:0 109 669 9069"
                >
                  {" "}
                  +20 109 669 9069
                </a>{" "}
                <br />
                <a
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    opacity: "0.5",
                  }}
                  href="tel: 0 100 366 0035"
                >
                  {" "}
                  +20 100 366 0035
                </a>
              </h4>
              <small>
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:aspropertieseg@gmail.com"
                >
                  aspropertieseg@gmail.com
                </a>
              </small>{" "}
            </div>

            <div class="language">
              {" "}
              <a
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setAr("ar");
                  localStorage.setItem("lang", "ar");
                }}
              >
                AR
              </a>{" "}
            </div>
            {isMobile ? (
              <div class="hamburger" onClick={handleMobileMenuToggle}>
                {" "}
                <span></span> <span></span> <span></span>
                <span></span>{" "}
              </div>
            ) : (
              ""
            )}
          </div>

          <div class="menu" style={{ opacity: "1" }}>
            <ul>
              <li>
                <Link to="/" style={{ textDecoration: "none" }}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/gallery" style={{ textDecoration: "none" }}>
                  Gallery
                </Link>
              </li>
              <li><Link to='/property' style={{textDecoration:'none'}}>Property</Link>
       
        </li>
              <li>
                <Link to="/news" style={{ textDecoration: "none" }}>
                  News
                </Link>
              </li>
              <li>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
