import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import news1 from "../Images/news1.jpg";
import news2 from "../Images/news2.jpg";
import news3 from "../Images/news3.jpg";
import news4 from "../Images/news4.JPG";
import news5 from "../Images/news5.jpg";
import news6 from "../Images/news6.jpg";
import Fade from "react-reveal/Fade";
import axios from "axios";
import { baseUrl } from "../../utils/constants";
import Pagination from "../Pagination";

export default function Property() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 10;
  useEffect(() => {
    axios
      .get(`${baseUrl}/properties?page=${currentPage}&perPage=${perPage}`)
      .then((response) => {
        setProperties(response.data.data);
        setTotalPages(response.data.pagination.totalPages);

      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1> A.S. Properties </h1>
          <Fade bottom duration={1000} delay={500}>
            {" "}
            <p>Advanced Solutions to your new home</p>
          </Fade>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                Home
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              A.S. Properties
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{ padding: "60px 0" }}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp">
              {" "}
              <b>01</b>
              <Fade bottom duration={1000} delay={500}>
                {" "}
                <h4>
                  <span>A.S.</span> Properties
                </h4>
              </Fade>
              <small>Read Our Properties</small>{" "}
            </div>
            {properties.map((post) => (
              <div class="col-lg-4 wow fadeInUp" data-wow-delay="0s">
                <div class="post-box">
                  <figure>
                    {" "}
                    <Link to={`/property/${post._id}`}>
                      <img src={post.images[0]} alt="Image" />{" "}
                    </Link>
                  </figure>
                  <h6 className="d-block w-100">
                    <Link
                      to={`/property/${post._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {post.title}
                    </Link>
                  </h6>

                  <p className="d-block w-100"> {post.shortDescription}</p>
                  <Link to={`/property/${post._id}`}>
                    <button className="readMore">
                      Read More &nbsp;<i class="fas fa-caret-right"></i>
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          <Pagination
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
          </div>
         
        </div>
      </section>
    </>
  );
}
