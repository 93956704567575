import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";

import { baseUrl } from "../../utils/constants";
import axios from "axios";



export default function Gallery() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/banner`)
      .then((response) => {
        setGallery(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);
  return (
    <>
      <header className="page-header" data-stellar-background-ratio="1.15">
        <div className="container">
          <h1>Gallery</h1>
          <Fade bottom duration={1000} delay={500}>
            <p>Discover your dream home in our captivating gallery</p>
          </Fade>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                Home
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Gallery
            </li>
          </ol>
        </div>
      </header>

      <section
        className="section-padding"
        style={{ paddingTop: "60px", paddingBottom: "60px" }}
      >
        <div className="container">
          <div className="galleryContainer">
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={{ clickable: true }}
              navigation
              loop={true}
              speed={500}
              slidesPerView={1}
            >
              {gallery.map((photo) => (
                <SwiperSlide key={photo._id}>
                  <img
                    src={photo.image}
                    style={{
                      height: "600px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt={photo.alt}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
