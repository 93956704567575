import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import news1 from "../Images/news1.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/constants";

export default function News1Ar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let { id } = useParams();
  const [post, setPost] = useState({});
  useEffect(() => {
    const headers = { lang: "ar" };

    axios
      .get(`${baseUrl}/blog/${id}`, { headers })
      .then((response) => {
        setPost(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1 dir="rtl" lang="ar">
            {" "}
            اخبار A.S. Properties{" "}
          </h1>
          <p dir="rtl" lang="ar">
            حلول متقدمة لمنزلك الجديد
          </p>
          <ol
            class="breadcrumbAr"
            dir="rtl"
            lang="ar"
            style={{
              position: "absolute",
              right: "15px",
              bottom: "-30px",
              background: "#9f8054",
              width: "85%",
              padding: "40px 15px",
              left: "auto",
              margin: "0",
            }}
          >
            <li class="breadcrumbAr-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                الرئيسية
              </Link>
            </li>
            <li class="breadcrumbAr-item active" aria-current="page">
              الأخبار{" "}
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{ padding: "40px 0" }}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center" dir="rtl" lang="ar">
              {" "}
              {/* <b>01</b> */}
              <h1 dir="rtl" lang="ar">
                {post.title}{" "}
              </h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={post.image} alt="" />
            </div>
            <div className="col-md-12">
              <p
                style={{ textAlign: "justify", whiteSpace: "pre-line" }}
                dir="rtl"
                lang="ar"
              >
                {post.description}{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
