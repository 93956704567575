import React, { useEffect , useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Fade from "react-reveal/Fade";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import { baseUrl } from "../../utils/constants";
import axios from "axios";

export default function GalleryAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl}/banner`)
      .then((response) => {
        setGallery(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1 dir="rtl" lang="ar">
            {" "}
            المعرض
          </h1>
          <Fade bottom duration={1000} delay={500}>
            {" "}
            <p dir="rtl" lang="ar">
              نحن نتطلع إلى الاستماع منك! إذا كان لديك أي أسئلة أو استفسارات، أو
              ترغب في ترتيب زيارة لعقاراتنا، فلا تتردد في التواصل معنا.
            </p>
          </Fade>
          <ol
            class="breadcrumbAr"
            dir="rtl"
            lang="ar"
            style={{
              position: "absolute",
              right: "15px",
              bottom: "-30px",
              background: "#9f8054",
              width: "85%",
              padding: "40px 15px",
              left: "auto",
              margin: "0",
            }}
          >
            <li class="breadcrumbAr-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                الرئيسية
              </Link>
            </li>
            <li class="breadcrumbAr-item active" aria-current="page">
              المعرض{" "}
            </li>
          </ol>
        </div>
      </header>

      <section
        className="section-padding"
        style={{ paddingTop: "60px", paddingBottom: "60px" }}
      >
        <div className="container">
          <div className="galleryContainer">
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={{ clickable: true }}
              navigation
              loop={true}
              speed={500}
              slidesPerView={1}
            >
              {gallery.map((photo) => (
                <SwiperSlide key={photo._id}>
                  <img
                    src={photo.image}
                    style={{
                      height: "600px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt={photo.alt}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
