import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import news2 from "../Images/news2.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../utils/constants";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper";
export default function News2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let { id } = useParams();
  const [property, setProperty] = useState({});
  useEffect(() => {
    axios
      .get(`${baseUrl}/properties/${id}`)
      .then((response) => {
        setProperty(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1> A.S. Properties </h1>
          <p>Advanced Solutions to your new home</p>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                Home
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              A.S. Properties
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{ padding: "40px 0" }}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center">
              {" "}
              {/* <b>01</b> */}
              <h1>{property.title}</h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={30}
                loop
                navigation
                pagination={{ clickable: true }}
                autoplay={{ delay: 5000 }}
                breakpoints={{
                  320: { slidesPerView: 1 }, // Mobile phones
                  768: { slidesPerView: 1 }, // Tablets
                  1024: { slidesPerView: 1 }, // Small desktops
                  1440: { slidesPerView: 1 }, // Large desktops
                }}
              >
                {property?.images?.map((post, index) => (
                  <SwiperSlide key={index}>
                    <div className=" text-center">
                      <figure>
                        <img
                          src={post}
                          alt={post.title}
                          style={{
                            minHeight: "300px",
                            height: "60vh",
                            width: "100%",
                          }}
                        />
                      </figure>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-md-12">
              <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>
                {property.description}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
